<template>
  <div class="setting">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="6" sm="6" xs="6" class="ma-0 pa-0 setting-left">
        <!--头像-->
        <div class="title x-title">
          <div class="staff">
            <!--<v-avatar class="avator" size="77">-->
            <!--  <img-->
            <!--    :src="'files/'+attendeesDataLeft.image"-->
            <!--  >-->
            <!--</v-avatar>-->
            <v-avatar class="avator" size="77" color="#707070">
              <!-- <img :src="imgDataUrl" v-if="attendeesDataLeft.image" alt="" /> -->
              <newImg
                :fileUrl="imgDataUrl"
                v-if="attendeesDataLeft.image"
              ></newImg>
              <span v-else class="white--text text-h5">
                {{ attendeesDataLeft.firstName[0] }}&nbsp;{{
                  attendeesDataLeft.lastName[0]
                }}
              </span>
              <!--<img-->
              <!--  :src="imgDataUrl"-->
              <!--&gt;-->
            </v-avatar>
            <div class="btn">
              <v-btn
                outlined
                rounded
                color="#fff"
                @click="toggleShow"
                tabindex="1"
                >Update Photo
              </v-btn>
            </div>
          </div>
          <my-upload
            field="img"
            class="uploadImg"
            tabindex="0"
            @crop-success="cropSuccess"
            v-model="show"
            :width="300"
            :height="300"
            langType="'en'"
            img-format="png"
          ></my-upload>
        </div>
        <!--左侧详细信息-->
        <div class="content">
          <!--basic info-->
          <div class="content-top">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="4" tabindex="2">First Name</v-col>
              <v-col class="ma-0 pa-0" cols="8">
                <v-text-field
                  aria-label="First Name"
                  v-model="attendeesDataLeft.firstName"
                  required
                  autocomplete="off"
                  tabindex="3"
                  :readonly="client == 'DoorDash' || 'Pepsico'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="4" tabindex="3">Last Name</v-col>
              <v-col class="ma-0 pa-0" cols="8">
                <v-text-field
                  aria-label="Last Name"
                  v-model="attendeesDataLeft.lastName"
                  required
                  autocomplete="off"
                  tabindex="4"
                  :readonly="client == 'DoorDash' || 'Pepsico'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="4" tabindex="5">
                <span v-if="client == 'NatWest'">Job Title/Role</span>
                <span v-else>Title</span>
              </v-col>
              <v-col class="ma-0 pa-0" cols="8">
                <v-text-field
                  v-model="attendeesDataLeft.title"
                  aria-label="Title"
                  required
                  tabindex="6"
                  readonly="client=='DoorDash'"
                  :readonly="client == 'DoorDash' || 'Pepsico'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="4" tabindex="7">
                <span v-if="client == 'NatWest'">Business Area</span>
                <span v-else>Company</span>
              </v-col>
              <v-col class="ma-0 pa-0" cols="8">
                <v-text-field
                  v-model="attendeesDataLeft.company"
                  aria-label="Company"
                  required
                  autocomplete="off"
                  tabindex="8"
                  :readonly="client == 'DoorDash' || 'Pepsico'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-show="!client == 'NatWest'">
              <v-col class="ma-0 pa-0" cols="4" tabindex="9">Phone</v-col>
              <v-col class="ma-0 pa-0" cols="8">
                <v-text-field
                  v-model="attendeesDataLeft.phoneNumber"
                  aria-label="Phone"
                  required
                  autocomplete="off"
                  tabindex="10"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-show="!client == 'NatWest'">
              <v-col class="ma-0 pa-0" cols="4" tabindex="11"
                >Display Phone Number</v-col
              >
              <v-col class="ma-0 pa-0" cols="8">
                <v-switch
                  color="green"
                  v-model="attendeesDataLeft.isPhoneShown"
                  inset
                  tabindex="12"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-show="client == 'Lenovo Corporate'">
              <v-col class="ma-0 pa-0" cols="4" tabindex="11"
                >Share my booking desk locations</v-col
              >
              <v-col class="ma-0 pa-0" cols="8">
                <v-switch
                  color="green"
                  v-model="attendeesDataLeft.locationSharing"
                  inset
                  tabindex="12"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row
              class="ma-0"
              v-if="
                client != 'Applied Materials' &&
                client != 'Pepsico' &&
                isShowRemoveFun
              "
              style="padding: 20px 0"
            >
              <v-col class="ma-0 pa-0" cols="4" tabindex="13"
                >Update Password</v-col
              >
              <v-col
                class="ma-0 pa-0"
                cols="8"
                style="color: #0000cc"
                tabindex="14"
              >
                <span
                  @click="updatePwd"
                  @keyup.enter="updatePwd"
                  style="cursor: pointer"
                >
                  Click to Update
                </span>
              </v-col>
            </v-row>
            <v-row
              class="pa-0"
              style="margin: 20px 0px"
              v-show="client != 'Pepsico'"
            >
              <v-col class="ma-0 pa-0" cols="12" tabindex="15"> Bio </v-col>
              <v-col class="ma-0 pa-0" cols="12">
                <v-textarea
                  aria-label="Bio"
                  outlined
                  background-color="white"
                  color="#EE2C54"
                  v-model="attendeesDataLeft.bio"
                  tabindex="16"
                  :readonly="client == 'Pepsico'"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
          <!--location-->
          <div
            class="locationInfo x-background"
            v-if="!mecToSetting"
            tabindex="17"
          >
            <p class="sp1">My Default Booking Location</p>
            <p class="sp2">
              Select your default location for booking spaces by choosing a
              building and floor.
            </p>
            <p>
              When you search for available spaces by "Default" , or if your
              current location cannot be found , the results will be aligned to
              this location
            </p>
          </div>
          <!--btn location-->
          <div class="btns" v-if="!mecToSetting">
            <div
              class="btns-btn"
              style="background: #f3f3f3"
              @click="openSelect"
              @keyup.enter="openSelect"
              tabindex="18"
            >
              <span class="btns-btn-title">Default Building</span>
              <span class="btns-btn-content">{{ buildingName }}</span>
            </div>
            <div
              class="btns-btn"
              style="background: #fff"
              @click="openSelect"
              @keyup.enter="openSelect"
              tabindex="19"
            >
              <span class="btns-btn-title">Default Floor</span>
              <span class="btns-btn-content">{{ floorName }}</span>
            </div>
            <div
              class="btns-btn"
              v-if="showDelegateFunction"
              style="background: #f3f3f3"
              @click="clickManageDelegates"
              @keyup.enter="clickManageDelegates"
              tabindex="20"
            >
              <span class="btns-btn-title">Manage Delegates</span>
            </div>
          </div>
          <!--按钮组-->
          <div class="operations">
            <div class="operations-one">
              <v-btn
                rounded
                class="x-btn"
                @click="clickPreview"
                @keyup.enter="clickPreview"
                tabindex="20"
                >Preview Updates>
              </v-btn>
              <v-btn
                rounded
                outlined
                @click="clickRemove"
                @keyup.enter="clickRemove"
                tabindex="21"
                v-if="isShowRemoveFun"
                >Remove My Profile
              </v-btn>
            </div>
            <div class="operations-bottom">
              <v-btn
                rounded
                class="x-btn"
                @click="clickSubmit"
                @keyup.enter="clickSubmit"
                tabindex="22"
                >Submit Profile Information>
              </v-btn>
              <v-btn
                rounded
                outlined
                @click="clickLogout"
                @keyup.enter="clickLogout"
                tabindex="23"
                >Log Out</v-btn
              >
            </div>
          </div>
        </div>

        <v-dialog v-model="selectFloor" max-width="290">
          <v-card class="dialog-window ma-0 pa-0">
            <div style="text-align: right; padding: 15px; padding-bottom: 0">
              <i
                class="iconfont icon-chahao"
                @click="selectFloor = false"
                @keyup.enter="selectFloor = false"
                style="cursor: pointer"
                tabindex="24"
              >
                <span style="letter-spacing: -8px; opacity: 0">close</span>
              </i>
            </div>
            <v-card-text>
              <div v-for="d in buildingsAndFloorsData" class="buildingFloor">
                <p class="buildingName">{{ d.buildingName }}</p>
                <p
                  v-for="(f, index) in d.floors"
                  tabindex="25"
                  class="floorName"
                  :class="{ selectColor: selectFloorId === f.id }"
                  @click="floorChange(d, f)"
                  @keyup.enter="floorChange(d, f)"
                >
                  {{ f.name }}
                  <v-icon
                    dense
                    color="blue darken-2"
                    style="float: right"
                    v-if="selectFloorId === f.id"
                    >mdi-check
                  </v-icon>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="6" class="ma-0 pa-0 setting-right">
        <staffInfo
          :data="attendeesDataRight"
          ref="refStaffInfo"
          :showAttributesFunction="showAttributesFunction"
          @changeShowList="changeShowList"
          @showMore="showMore"
          @attritutesSearch="attritutesSearch"
        >
        </staffInfo>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelegates" max-width="800">
      <selectPa
        ref="selectPa"
        v-on:backToBooking="backToBooking"
        v-on:addPa="addPa"
        :pa="participants"
        :hasborder="false"
        :isDelegate="true"
        :selectType="'setting'"
      ></selectPa>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import staffInfo from "@/views/dashboard/componentsHa/settingStaffInfo";
import { mapState, mapGetters, mapMutations } from "vuex";
import myUpload from "vue-image-crop-upload";
import selectPa from "@/views/dashboard/componentsHa/selectPa";
import showDelegate from "@/util/delegate";
import newImg from "@/views/dashboard/componentsHa/img";

export default {
  name: "setting",
  components: {
    staffInfo,
    selectPa,
    "my-upload": myUpload,
    newImg,
  },
  data() {
    return {
      showDelegateFunction: false,
      participants: [],
      dialogDelegates: false, //选择代理人弹窗
      client: "", //当前客户
      show: false,
      imgDataUrl: "",
      imgDataUrlOrigin: "", //初始头像
      en: {
        hint: "Click or drag the image here to upload",
        loading: "Uploading…",
        noSupported:
          "Browser is not supported, please use IE10+ or other browsers",
        success: "Upload success",
        fail: "Upload failed",
        preview: "Preview",
        btn: {
          off: "Cancel",
          close: "Close",
          back: "Back",
          save: "Save",
        },
        error: {
          onlyImg: "Image only",
          outOfSize: "Image exceeds size limit: ",
          lowestPx: "Image's size is too low. Expected at least: ",
        },
      },

      attendeesDataLeft: {},
      attendeesDataOrigin: {},
      attendeesDataRight: {},
      selectFloor: false,
      buildingName: "",
      floorName: "",
      buildingsAndFloorsData: [],
      selectFloorId: "",
      selectBuildingId: "",
      mecToSetting: false,
      showAttributesFunction: false,
      showOrganazation: false, //显示员工组织架构
      reportToIndex: 0,
      reportFromIndex: 0,
      orgStructureSize: 5,
      reportToTotal: 0,
      reportFromTotal: 0,
      isShowRemoveFun: true,
    };
  },
  created() {
    this.showDelegateFunction = showDelegate;
    this.client = localStorage.getItem("client");
    // 先选了campus再进setting就显示default building和floor ，否则不显示
    if (!localStorage.meetingIdMap) {
      this.mecToSetting = true;
    } else {
      this.getBuildingsAndFloorsData();
      this.mecToSetting = false;
    }
    if (localStorage.client && localStorage.client == "CX Campus") {
      this.showAttributesFunction = true;
    }
    // console.log('啊神',this.$store.state.confidanielDomain);
    if (this.$store.state.confidanielDomain == true) {
      this.isShowRemoveFun = false;
    }
    // if (this.getMecToSetting === true && localStorage.meetingIdMap === '') {
    //   this.mecToSetting = true;
    // } else {
    //   if(localStorage.meetingIdMap === ''){
    //     this.mecToSetting = true;
    //   }else{
    //     this.getBuildingsAndFloorsData();
    //     this.mecToSetting = false;
    //   }
    // }
  },
  computed: {
    ...mapGetters("mecToSetting", ["getMecToSetting"]),
  },
  methods: {
    ...mapMutations("mecToSetting", ["setMecToSetting"]),
    ...mapMutations("avator", ["setAvator"]),
    ...mapMutations("campusName", ["setCampusName"]),
    // 点击Manage Delegates
    clickManageDelegates() {
      this.dialogDelegates = true;
      this.$axios({
        method: "get",
        url: "/m/spaces/delegateUsers",
      })
        .then((res) => {
          console.log("GETdelegateUsers:", res);
          this.participants = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // if (window.gtag) {
      //   window.gtag("event", "Setting", {
      //     event_category: "Manage Delegate Click",
      //     event_label: "Manage Delegate",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Setting', {
        event_category: "Manage Delegate Click",
        event_label: "Manage Delegate",
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || ""
      });
    },
    // 更新delegate
    updateDelegate(data) {
      this.bus.$emit("loading", true);
      console.log("==== 更新delegate=====", data);
      let arr = [];
      data.map((item) => {
        arr.push(item.id);
      });
      this.$axios({
        method: "post",
        url: "/m/spaces/delegateUsers",
        data: {
          users: arr,
        },
      })
        .then((res) => {
          this.bus.$emit("loading", false);
          console.log("res:", res);
          this.dialogDelegates = false;
          this.$message.success("Delegate(s) Added Successfully");
        })
        .catch((err) => {
          console.log(err);
          this.bus.$emit("loading", false);
        });
    },
    backToBooking() {
      this.dialogDelegates = false;
    },
    addPa(data) {
      console.log("==== data=====", data);
      this.updateDelegate(data);
    },
    updatePwd() {
      this.bus.$emit("loading", true);
      let _this = this;
      let url = "";
      let data = {};
      url = "m/forgotPassword/";
      data.email = localStorage.userEmail;
      axios
        .post(url, data)
        .then((response) => {
          this.bus.$emit("loading", false);
          if (response.data.result === "succeeded") {
            // _this.forgotPasswordDialog = true;
            this.$confirm({
              title: "Confirmation",
              message:
                "We've sent you an email to update your password. If you don't see it in your inbox, please check your Spam folder.",
              show: true,
            });
          } else {
            this.$confirm({
              title: "Confirmation",
              message: response.data.error,
              show: true,
            });
            // _this.$message.error(response.data.error);
          }
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    toggleShow() {
      if (this.client == "Pepsico") {
        return;
      }
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
      this.attendeesDataLeft.image = imgDataUrl;
    },
    getUserLocation() {
      let _this = this;
      this.$axios({
        method: "get",
        url: "m/campus/userDefaultLocation",
        data: {
          meetingId: localStorage.getItem("meetingIdMap"),
        },
      })
        .then((res) => {
          if (res.data.result === "succeeded") {
            // _this.buildingName = res.data.defaultBuilding;
            // _this.floorName = res.data.defaultFloor;
            let bId = res.data.data.defaultBuilding;
            let fId = res.data.data.defaultFloor;
            let length = _this.buildingsAndFloorsData.length;
            for (let i = 0; i < length; i++) {
              let item = _this.buildingsAndFloorsData[i];
              if (item.id == bId) {
                _this.buildingName = item.buildingName;
                let length = item.floors.length;
                for (let i = 0; i < length; i++) {
                  let jtem = item.floors[i];
                  if (jtem.id == fId) {
                    _this.floorName = jtem.name;
                    break;
                  }
                }
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBuildingsAndFloorsData() {
      let _this = this;
      this.$axios({
        method: "get",
        url: this.$usev2 ? "m/v2/spaces/maps" : "m/spaces/maps",
        data: {
          meetingId: localStorage.getItem("meetingIdMap"),
        },
      })
        .then((res) => {
          if (res.data.result === "succeeded") {
            _this.buildingsAndFloorsData = res.data.data;
            console.log(
              "buildingsAndFloorsData:",
              _this.buildingsAndFloorsData
            );
            this.getUserLocation();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // getBuildingsAndFloorsData() {
    //   let url = "";
    //   let _this = this;
    //   // url = "m/campus/maps";
    //   url = "m/spaces/maps";
    //   if (localStorage.meetingIdMap) {
    //     url += `?meetingId=${localStorage.meetingIdMap}&`;
    //   }
    //   // if (localStorage.token) {
    //   //   url += `token=${localStorage.token}&`;
    //   // }
    //   this.get(url, {})
    //     .then((res) => {
    //       if (res.result === "succeeded") {
    //         _this.buildingsAndFloorsData = res.data;
    //         console.log('buildingsAndFloorsData:', _this.buildingsAndFloorsData);
    //         this.getUserLocation();
    //       } else {
    //         //这里抛出的异常被下面的catch所捕获
    //         reject(error);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // },

    openSelect() {
      let _this = this;
      _this.buildingsAndFloorsData.map((i) => {
        if (i.buildingName === _this.buildingName) {
          i.floors.map((f) => {
            if (f.name === _this.floorName) {
              _this.selectFloorId = f.id;
            }
          });
        }
      });
      _this.selectFloor = true;
      // if (window.gtag) {
      //   window.gtag("event", "Setting", {
      //     event_category: "Default Location Click",
      //     event_label: "Default Location",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Setting', {
        event_category: "Default Location Click",
        event_label: "Default Location",
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || ""
      });
    },
    floorChange(building, floor) {
      // console.log(buildingName, floor);
      this.selectBuildingId = building.id;
      this.selectFloorId = floor.id;
      this.buildingName = building.buildingName;
      this.floorName = floor.name;
      this.selectFloor = false;
    },
    // floorChange(buildingName, floor) {
    //   // console.log(buildingName, floor);
    //   this.selectFloorId = floor.id;
    //   this.buildingName = buildingName;
    //   this.floorName = floor.name;
    //   this.selectFloor = false;
    // },
    // 获取员工信息
    getAttendees() {
      let attendeeId = localStorage.getItem("userId");
      console.log("attendeeId:", attendeeId);
      this.$axios({
        method: "GET",
        url: "/m/attendees/",
        data: {
          attendeeId: attendeeId,
          // token: localStorage.token
        },
      })
        .then(async (res) => {
          console.log("attendee:", res);
          this.attendeesDataOrigin = JSON.parse(JSON.stringify(res.data));
          this.attendeesDataLeft = JSON.parse(JSON.stringify(res.data));
          this.attendeesDataRight = JSON.parse(JSON.stringify(res.data));
          localStorage.setItem("image", res.data.image);
          console.log(
            "this.attendeesDataLeft.image:",
            this.attendeesDataLeft.image
          );
          if (this.attendeesDataLeft.image == "avatar.jpg") {
            this.attendeesDataLeft.image = "";
          }
          this.imgDataUrl = "files/" + this.attendeesDataLeft.image;
          this.imgDataUrlOrigin = "files/" + this.attendeesDataLeft.image;
          this.setAvator(this.imgDataUrl); //左侧导航监听到store改变会去locaoStorage拿新头像
          this.reportToIndex = 0;
          this.reportFromIndex = 0;
          // console.log(this.staffDetail,'009o9');
          // if(this.staffDetail.hasOwnProperty('orgStructure')){
          //   this.bus.$emit('loading', false);
          // }else{
          let orgStructureTo;
          await this.getOrgStructure("to", this.attendeesDataRight.id).then(
            (res) => {
              orgStructureTo = res;
            }
          );
          let orgStructureFrom;
          await this.getOrgStructure("from", this.attendeesDataRight.id).then(
            (res) => {
              orgStructureFrom = res;
            }
          );
          let orgStructure = [];
          orgStructure.reportFrom = orgStructureFrom;
          orgStructure.reportTo = orgStructureTo;
          this.attendeesDataRight.orgStructure = orgStructure;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 预览修改
    clickPreview() {
      this.attendeesDataRight.name = this.attendeesDataLeft.firstName + " " + this.attendeesDataLeft.lastName;
      this.attendeesDataRight.title = this.attendeesDataLeft.title;
      this.attendeesDataRight.company = this.attendeesDataLeft.company;
      this.attendeesDataRight.phoneNumber = this.attendeesDataLeft.phoneNumber;
      this.attendeesDataRight.isPhoneShown = this.attendeesDataLeft.isPhoneShown;
      this.attendeesDataRight.bio = this.attendeesDataLeft.bio;
      this.attendeesDataRight.image = this.imgDataUrl.replace("files/", "");
      // if (window.gtag) {
      //   window.gtag("event", "Setting", {
      //     event_category: "Preview Click",
      //     event_label: "Preview",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Setting', {
        event_category: "Preview Click",
        event_label: "Preview",
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || ""
      });
    },
    clickRemove() {
      this.attendeesDataLeft = JSON.parse(
        JSON.stringify(this.attendeesDataOrigin)
      );
      this.attendeesDataRight = JSON.parse(
        JSON.stringify(this.attendeesDataOrigin)
      );
      this.imgDataUrl = "files/" + this.attendeesDataLeft.image;
      // if (window.gtag) {
      //   window.gtag("event", "Setting", {
      //     event_category: "Remove My Profile Click",
      //     event_label: "Remove My Profile",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Setting', {
        event_category: "Remove My Profile Click",
        event_label: "Remove My Profile",
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || ""
      });

      // console.log('attendeesDataOrigin:',this.attendeesDataOrigin);
      // console.log('attendeesDataLeft:',this.attendeesDataLeft);
    },

    // 提交修改
    clickSubmit() {
      this.bus.$emit("loading", true);
      let _this = this;
      var p1 = new Promise((resolve, reject) => {
        _this
          .$axios({
            method: "post",
            url: "/m/update-profile/",
            data: {
              firstName: _this.attendeesDataLeft.firstName,
              lastName: _this.attendeesDataLeft.lastName,
              title: _this.attendeesDataLeft.title,
              company: _this.attendeesDataLeft.company,
              phoneNumber: _this.attendeesDataLeft.phoneNumber,
              bio: _this.attendeesDataLeft.bio,
              isPhoneShown: _this.attendeesDataLeft.isPhoneShown,
              locationSharing: _this.attendeesDataLeft.locationSharing,
              userId: localStorage.userId,
              // token: localStorage.token,
              imageData:
                this.imgDataUrl == this.imgDataUrlOrigin ? "" : this.imgDataUrl,
            },
          })
          .then((res) => {
            resolve(res);
            // if (window.gtag) {
            //   window.gtag("event", "Setting", {
            //     event_category: "Submit Profile Information Click",
            //     event_label: "Submit Profile Information",
            //     send_to: localStorage.getItem("googleAnaId") || "",
            //     dimension1: localStorage.getItem("campusName") || ""
            //   });
            // }
            this.$gtag.event('Setting', {
              event_category: "Submit Profile Information Click",
              event_label: "Submit Profile Information",
              send_to: localStorage.getItem("googleAnaId") || "",
              dimension1: localStorage.getItem("campusName") || ""
            });
          })
          .catch((err) => {
            this.bus.$emit("loading", false);
            reject(err);
          });
      });
      var p2 = new Promise((resolve, reject) => {
        if (_this.mecToSetting == true || localStorage.meetingIdMap == "") {
          resolve("Success");
        } else {
          _this
            .$axios({
              method: "post",
              // url: '/m/campus/userDefaultLocation?token=' + localStorage.token,
              url: "/m/campus/userDefaultLocation",
              data: {
                // defaultBuilding: _this.buildingName,
                // defaultFloor: _this.floorName,
                defaultBuilding: _this.selectBuildingId,
                defaultFloor: _this.selectFloorId,
                meetingId: localStorage.meetingIdMap,
              },
            })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              this.bus.$emit("loading", false);
              reject(err);
            });
        }
      });
      Promise.all([p1, p2])
        .then((result) => {
          this.bus.$emit("loading", false);
          _this.$message.success("Success");
          _this.getAttendees();
        })
        .catch((error) => {
          this.bus.$emit("loading", false);
          console.log(error);
        });
    },
    // 登出
    clickLogout() {
      // this.setCampusName('At a Glance');
      this.bus.$emit("loading", true);
      this.$axios({
        method: "post",
        url: "/m/logout",
      })
        .then((res) => {
          // localStorage.clear();
          // sessionStorage.clear();
          localStorage.removeItem("client");
          localStorage.removeItem("config");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("userName");
          localStorage.removeItem("workingHourStartTime");
          localStorage.removeItem("workingHourEndTime");
          localStorage.removeItem("showDeskButton");
          localStorage.removeItem("showRoomButton");
          localStorage.removeItem("advanceBookingLimit");
          localStorage.removeItem("advanceRoomBookingLimit");
          this.$router.push({ name: "LoginEmail" });
          this.bus.$emit("loading", false);
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("client");
          localStorage.removeItem("config");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("userName");
          localStorage.removeItem("workingHourStartTime");
          localStorage.removeItem("workingHourEndTime");
          localStorage.removeItem("showDeskButton");
          localStorage.removeItem("showRoomButton");
          localStorage.removeItem("advanceBookingLimit");
          localStorage.removeItem("advanceRoomBookingLimit");
          // localStorage.clear();
          // sessionStorage.clear();
          this.$router.push({ name: "LoginEmail" });
          this.bus.$emit("loading", false);
        });
      // if (window.gtag) {
      //   window.gtag("event", "Setting", {
      //     event_category: "Log Out Click",
      //     event_label: "Log Out",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   });
      // }
      this.$gtag.event('Setting', {
        event_category: "Log Out Click",
        event_label: "Log Out",
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || ""
      });
    },
    attritutesSearch(data) {
      localStorage.skill = data;
      console.log(data);
      this.$router
        .push({
          path: "/globalDirectory",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // reportTo或reportFrom显示更多的人
    async showMore(type, id) {
      this.bus.$emit("loading", true);
      if (type == "to") {
        this.reportToIndex++;
        let orgStructureTo;
        await this.getOrgStructure("to", id).then((res) => {
          orgStructureTo = res;
        });
        this.$refs["refStaffInfo"].showReportFromList = false;
        this.attendeesDataRight.orgStructure.reportTo =
          this.attendeesDataRight.orgStructure.reportTo.concat(orgStructureTo);
        this.$refs["refStaffInfo"].showReportToList = false;
        if (
          this.attendeesDataRight.orgStructure.reportTo.length >=
          this.reportToTotal
        ) {
          this.$refs["refStaffInfo"].showReportToList = true;
        }
        this.bus.$emit("loading", false);
      } else {
        this.reportFromIndex++;
        let orgStructureFrom;
        await this.getOrgStructure("from", id).then((res) => {
          orgStructureFrom = res;
        });
        // console.log("====orgStructureFrom =====",orgStructureFrom)
        this.$refs["refStaffInfo"].showReportFromList = false;
        this.staffDetail.orgStructure.reportFrom =
          this.staffDetail.orgStructure.reportFrom.concat(orgStructureFrom);
        this.$refs["refStaffInfo"].showReportFromList = true;
        if (
          this.attendeesDataRight.orgStructure.reportFrom.length >=
          this.reportFromTotal
        ) {
          this.$refs["refStaffInfo"].showMoreFrom = false;
        }
        this.bus.$emit("loading", false);
        // console.log("====this.staffDetail =====",this.staffDetail)
      }
    },
    // 右侧点击report to 或 reporting to的list
    changeShowList(data, showCloseBtn) {
      this.showStaffDetail(data, showCloseBtn, false); //第二个参数是用来控制右上角的关闭按钮显示与否
    },
    // 单独获取OrgStructure数据接口
    async getOrgStructure(type, attendeeId) {
      let data;
      // let url = '/m/attendess/'+attendeeId+'/orgStructure';
      await this.$axios({
        method: "GET",
        url: "/m/attendees/orgStructure",
        data: {
          type: type,
          page: type == "to" ? this.reportToIndex : this.reportFromIndex,
          size: this.orgStructureSize,
          attendeeId: attendeeId,
        },
      })
        .then((res) => {
          console.log("8888888");
          data = res.data.list;
          if (type == "to") {
            this.reportToTotal = res.data.total;
          } else {
            this.reportFromTotal = res.data.total;
          }
        })
        .catch((err) => {});
      return data;
    },
    // 显示右侧员工详细信息
    showStaffDetail(data, showCloseBtn = false, changeFirstIn = true) {
      console.log("data;", data, showCloseBtn);
      let that = this;
      this.bus.$emit("loading", true);
      this.$axios({
        method: "GET",
        url: "/m/attendees/",
        data: {
          meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : "",
          attendeeId: data.id,
        },
      })
        .then(async (res) => {
          // data.locationShareStatus = res.data.locationShareStatus;
          this.attendeesDataRight = res.data;
          if (data.department) {
            this.attendeesDataRight.department = data.department;
          }
          this.reportToIndex = 0;
          this.reportFromIndex = 0;

          let orgStructureTo;
          await this.getOrgStructure("to", data.id).then((res) => {
            orgStructureTo = res;
          });
          let orgStructureFrom;
          await this.getOrgStructure("from", data.id).then((res) => {
            orgStructureFrom = res;
          });
          let orgStructure = [];
          orgStructure.reportFrom = orgStructureFrom;
          orgStructure.reportTo = orgStructureTo;
          this.attendeesDataRight.orgStructure = orgStructure;
          console.log("siiii", this.attendeesDataRight);
          this.bus.$emit("loading", false);
          this.$nextTick(() => {
            console.log("====$refs =====", that.$refs.refStaffInfo);
            this.$refs.refStaffInfo.showCloseBtn = showCloseBtn;
            if (changeFirstIn) {
              this.$refs.refStaffInfo.firstInData = res.data;
            }
          });
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });

      // this.getAttendee(data.id).then(res => {
      //   // console.log('res:', res);
      //   this.staffDetail = res.data;
      //   this.showRight = true;
      // }).catch(err => {
      //   console.log(err)
      // })
    },
  },
  mounted() {
    this.getAttendees();
  },
};
</script>

<style lang="scss" scoped>
.setting {
  .setting-left {
    /*height: 650px;*/
    background: #fff;
    width: 100%;
    overflow: hidden;

    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 120px;
      text-align: center;
      padding: 0 20px;
      display: flex;
      border-right: 1px solid #f3f3f3;

      .staff {
        display: flex;
        align-items: center;

        .avator {
          margin-right: 20px;
        }

        .uploadImg {
          &focus {
            border: 5px solid black !important;
          }
        }
      }
    }

    .content {
      height: auto;
      background: #fff;
      border: 1px solid #999999;
      /*overflow-y: auto;*/
      /*width: calc(100% + 15px);*/

      .content-top {
        padding: 20px 50px;

        div {
          align-items: center;
        }
      }

      .locationInfo {
        //background: $main-blue;
        color: #fff;
        padding: 20px 50px;

        .sp1 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .sp2 {
          margin-bottom: 10px;
        }
      }
    }

    //.x-btn{
    //  background: $btn-color!important;
    //}
    //location
    .btns {
      .btns-btn {
        padding: 30px 50px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .btns-btn-title {
          font-weight: bold;
        }

        .btns-btn-content {
          color: #666666;
        }
      }
    }

    .operations {
      text-align: center;

      .operations-one {
        /*margin: 30px;*/
        button {
          min-width: 280px;
          margin: 30px 12px 0px 12px;
        }
      }

      .operations-bottom {
        margin-bottom: 30px;

        button {
          min-width: 280px;
          margin: 30px 12px 0px 12px;
        }
      }
    }
  }

  .setting-right {
  }
}

.buildingFloor {
  .buildingName {
    font-size: 1.5rem;
    color: #000000;
    font-weight: bold;
  }

  .floorName {
    font-size: 1rem;
    cursor: pointer;
  }
}

.selectColor {
  //color: $main-blue;
}
</style>
